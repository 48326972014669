import { IconObject, IconType } from '@components/Icon';

export const ExpLanguage: IconObject[][] = [
  [
    { iconType: IconType.JavaScript },
    { iconType: IconType.TypeScript },
    { iconType: IconType.Java },
    { iconType: IconType.Ruby },
    { iconType: IconType.Go },
  ],
];
export const FamLanguage: IconObject[][] = [
  [
    { iconType: IconType.Python },
    { iconType: IconType.Swift },
    { iconType: IconType.R },
    { iconType: IconType.Elixir },
    { iconType: IconType.Erlang },
  ],
  [{ iconType: IconType.AutoIt }],
];

export const ExpTech: IconObject[][] = [
  [
    { iconType: IconType.Redux },
    { iconType: IconType.React },
    { iconType: IconType.NextJs },
    { iconType: IconType.NodeJs },
    { iconType: IconType.Ionic },
  ],
  [
    { iconType: IconType.PostgreSql },
    { iconType: IconType.JQuery },
    { iconType: IconType.Tailwind },
    { iconType: IconType.Git },
    { iconType: IconType.Rails },
  ],
  [
    { iconType: IconType.MaterialUI },
    { iconType: IconType.BlueprintJs },
    { iconType: IconType.Sequelize },
    { iconType: IconType.ExpressJs },
    { iconType: IconType.Recoil },
  ],
  [{ iconType: IconType.Storybook }],
];
export const FamTech: IconObject[][] = [
  [{ iconType: IconType.AwsApiGateway }, { iconType: IconType.AwsDynamoDb }, { iconType: IconType.AwsLambda }, { iconType: IconType.Ecto }],
];
